export function px2Rem(val) {
    (function (n, val) {
        var e = n.document,
            t = e.documentElement,
            i = 375,
            d = i / val,
            o = "orientationchange" in n ? "orientationchange" : "resize",
            a = function () {
                var n = t.clientWidth || 320;
                n > 750 && (n = 750);
                t.style.fontSize = n / d + "px";
            };
        a()
        e.addEventListener &&
        (n.addEventListener(o, a, !1),
            e.addEventListener("DOMContentLoaded", a, !1));
    })(window, val);
}

export function px2Rem1() {
    let width = window.document.documentElement.clientWidth
    if (width <= 430 && width > 414) {
        return 180
    }
    if (width >= 375 && width <= 390) {
        return 250
    }
    return 250
}
