import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/index.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode:'history'
})

export default router
